<template>
  <v-container>
    <div v-if="!data_loaded">
      <v-col cols="2">
        <v-skeleton-loader type="list-item"></v-skeleton-loader>
      </v-col>
      <v-row>
        <v-col v-for="i in 3" :key="i" cols="6" sm="4">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="data_loaded">
      <h2 class="mb-4">{{ employee.name }}</h2>
      <v-row>
        <v-col cols="12" sm="3">
          <v-card height="80" color="blue ligthen-1" outlined>
            <v-card-text class="d-flex justify-space-between">
              <h3 class="text-center white--text">الساعات</h3>
              <div class="text-end">
                <h2 class="text-center white--text">
                  {{ employee.totals.hours.total }}
                </h2>
                <p class="font-weight-bold white--text pt-2">
                  ({{ employee.totals.hours.hours }}) ساعة
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="3">
          <v-card height="80" color="warning ligthen-1" outlined>
            <v-card-text class="d-flex justify-space-between">
              <h3 class="text-center white--text">الايام</h3>
              <div class="text-end">
                <h2 class="text-center white--text">
                  {{ employee.totals.days.total }}
                </h2>
                <p class="font-weight-bold white--text pt-2">
                  ({{ employee.totals.days.days }}) يوم
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="3">
          <v-card height="80" color="success ligthen-1" outlined>
            <v-card-text class="d-flex justify-space-between">
              <h3 class="text-center white--text">المدفوع</h3>
              <h2 class="text-center white--text">
                {{ employee.totals.total_paid }}
              </h2>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="3">
          <v-card height="80" color="error ligthen-1" outlined>
            <v-card-text class="d-flex justify-space-between align-center">
              <h3 class="text-center white--text">المتبقي</h3>
              <h2 class="text-center white--text">
                {{ employee.totals.total_rem }}
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "employee",
  data() {
    return {
      data_loaded: false,
      employee: {
        name: "",
        totals: {
          total_h: 0,
          total_d: 0,
          total_paid: 0,
          total_rem: 0,
        },
      },
    };
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `employees/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          this.employee = Object.assign({}, res.data.data);
          this.$nextTick(() => {
            this.data_loaded = true;
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
